
import React from "react"
import PostmortemformEditor from "../../components/negosyonow/controls/postmortemeditor";

const CustomerRatingPage = ({location}) => {

	return <PostmortemformEditor
				location={location}
				mode={1}
			/>

}

export default CustomerRatingPage;
