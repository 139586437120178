
import React, {useState} from "react"
import Layout from "../../layout"

import WebappForm from "../../webapps/form"

const webappsAPI = require("../../../../lib/requestWebapps");
const formatTools = require("../../../../lib/formatTools");

const ENTITYID="nnpostmortemreport"

const REPORTSTATUSID_DRAFT = 1;
const REPORTSTATUSID_SUBMITTED = 2;

const codetypelist = [
	{"value":0,"display":"Unknown/None"},
	{"value":1,"display":"Password"},
	{"value":2,"display":"Guest"},

];

const reportstatuslist = [
	{"value":REPORTSTATUSID_DRAFT,"display":"Draft"},
	{"value":REPORTSTATUSID_SUBMITTED,"display":"Submitted"},

];

const ratingoptionlist = [
	{"value":5,"display":"5 - Excellent"},
	{"value":4,"display":"4 - Good"},
	{"value":3,"display":"3 - OK"},
	{"value":2,"display":"2 - Needs improvement"},
	{"value":1,"display":"1 - Not acceptable"},
];


const onspersonpickerfields = [
	{"label":"ID Number", "dbfield": "onsperson_idnum", "type": "text", "filtertype": "textbox"},
	{"label":"Name", "dbfield": "onsperson_name", "type": "text", "filtertype": "textbox"},
	{"label":"Formal Name", "dbfield": "onsperson_msgname", "type": "text", "filtertype": "textbox"},
	{"label":"Contacts", "dbfield": "onsperson_contactsummary", "type": "text", "filtertype": "textbox"},
	{"label":"Login Type", "dbfield": "onsperson_codetype", "type": "combo", "filtertype": "combo", "options": codetypelist},
	{"label":"Transactions", "dbfield": "onsperson_transactionsummary", "type": "text", "filtertype": "textbox"},
];

const onspersonpickerfilter = [
	{"field":"onsperson_codetype", "operation": ">", "value": "0"},
];



const onscustomerpickerfields = JSON.parse(JSON.stringify(onspersonpickerfields).replace(/onsperson_/g, "onscustomer_"));

const onscustomerpickerfilter = JSON.parse(JSON.stringify(onspersonpickerfilter).replace(/onsperson_/g, "onscustomer_"));



const searchFieldDefault = [
	{"label": "Title", "dbfield": "nnpostmortemreport_title", "type": "text", "filtertype": "text"},
];


const searchFieldCustomer = [
	{"label": "Customer", "dbfield": "onscustomer_name", "type": "text", "filtertype": "text"},
	{"label": "Rating", "dbfield": "nnpostmortemreport_rating", "type": "combo", "filtertype":"combo", "options": ratingoptionlist},
];

const searchFieldsHeader = [
	{"label":"Date Filed", "dbfield": "nnpostmortemreport_datereported", "type": "datetime", "filtertype": "datetime"},
	{"label":"Doc #", "dbfield": "nnpostmortemreport_code", "type": "text", "filtertype": "text"},
];


const searchFieldsEnder = [
	{"label":"Description", "dbfield": "nnpostmortemreport_summary", "type": "textarea", "filtertype": "text"},
	{"label":"Documents", "dbfield": "nnpostmortemreport_details", "type": "textarea", "filtertype": "text"},
	{"label":"Encoder", "dbfield": "nnpostmortemreport_reportedby", "type": "text", "filtertype": "text"},
	{"label":"Status", "dbfield": "nnpostmortemreportstatus_id", "type": "combo", "filtertype": "combo", "options": reportstatuslist},
	{"label":"Last Updated", "dbfield": "nnpostmortemreport_updated", "type": "datetime", "filtertype": "datetime"},
];


const formFieldsCustomer = [
	{"label":"Customer", "field": "onscustomer_name", "value": "", "input": "picker", "mode": "required", "pickerfields": onscustomerpickerfields, "pickerfilters": onscustomerpickerfilter},
	{
		"label": "Rating",
		"field": "nnpostmortemreport_rating",
		"value": "",
		"input": "combo",
		"options": ratingoptionlist,
		"mode": "required",

	},

	//nnpostmortemreport_rating
];



const formFieldsHeader = [
	{"label":"Date Filed", "field": "nnpostmortemreport_datereported", "value": "", "input": "createtime", "mode": "readonly"},
	{"label":"Doc #", "field": "nnpostmortemreport_code", "value": "", "input": "textbox", "mode": "readonly"},
	//{"label":"Title", "field": "nnpostmortemreport_title", "value": "", "input": "textbox", "mode": "required"},
];


const formFieldsEnder = [
	{"label":"Description", "field": "nnpostmortemreport_summary", "value": "", "input": "textarea", "mode": "required"},
	//{"label":"Location", "field": "nnpostmortemreport_location", "value": "", "input": "textarea", "mode": "normal"},
	{"label":"Dates/References", "field": "nnpostmortemreport_details", "value": "", "input": "textarea", "mode": "normal", "info":"eg. Date, Order number, payment reference, etc"},
	//{"label":"Notes/Context", "field": "nnpostmortemreport_notes", "value": "", "input": "textarea", "mode": "normal", "info":"Other information that might be useful for third-party reviewers"},

	{"label":"Encoder", "field": "nnpostmortemreport_reportedby", "value": "", "input": "textbox", "mode": "readonly"},
	{"label":"Status", "field": "nnpostmortemreportstatus_id", "value": "", "input": "combo", "mode": "required", "options": reportstatuslist},
	{"label":"Last Updated", "field": "nnpostmortemreport_updated", "value": "", "input": "updatetime", "mode": "readonly"},

	{"label":"Add an update", "field": "nnpostmortemreport_scratch", "value": "", "input": "textarea", "mode": "normal", "info":"Updates"},
];


const subformFields = [
	{
		"subformid": "nnpostmortemreportupdates",
		"label": "Detailed History/Updates",
		"table": "nnpostmortemreportupdates",
		"sort": "nnpostmortemreportupdates_datetime desc",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 0,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Date/Time",
				"field": "nnpostmortemreportupdates_datetime",
				"subtotalid": "",
				"value": "",
				"input": "createtime",
				"mode": "readonly",
			},
			{
				"label": "Details",
				"field": "nnpostmortemreportupdates_details",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly",
			},
			{
				"label": "Contact",
				"field": "nnpostmortemreportupdates_encoder",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly",
			},
		],
		"footerlist": [
		]
	},
	{
		"subformid": "nnpostmortemreportattachment",
		"label": "Attachments",
		"table": "nnpostmortemreportattachment",
		"sort": "nnpostmortemreportattachment_datetime",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 10,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Date Added",
				"field": "nnpostmortemreportattachment_datetime",
				"subtotalid": "",
				"value": "",
				"input": "createtime",
				"mode": "readonly",
			},
			{
				"label":"File/Image",
				"field": "nnpostmortemreportattachment_file",
				"subtotalid": "",
				"value": "",
				"input": "flexibleimage",
				"mode": "required",
				"filehost": "private.cdn.ngnw.ph", "filelocation":"postmortem/uploads/file",
				"imagemode":"limit", "imagewidth":1024, "imageheight":1024, "imageformat":"jpg", "uploadtype":"webapp"
			},
			{
				"label": "Description",
				"field": "nnpostmortemreportattachment_notes",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "required"
			}
		],
		"footerlist": [
		]
	}
];

const subformFieldsCustomer = [
	{
		"subformid": "nnpostmortemreportcustomer",
		"label": "Customers",
		"table": "nnpostmortemreportcustomer",
		"sort": "onsperson_name",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 10,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Customer ID",
				"field": "onsperson_idnum",
				"subtotalid": "",
				"value": "",
				"input": "picker",
				"mode": "unique",
				"pickerfields": onspersonpickerfields,
				"pickerfilters": onspersonpickerfilter
			},
			{
				"label": "Customer Name",
				"field": "onsperson_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "readonly"
			},
			{
				"label": "Rating",
				"field": "nnpostmortemreportcustomer_rating",
				"subtotalid": "",
				"value": "",
				"input": "combo",
				"options": ratingoptionlist,
				"mode": "required",

			},
			{
				"label": "Reason/Details",
				"field": "nnpostmortemreportcustomer_notes",
				"subtotalid": "",
				"value": "",
				"input": "textarea",
				"mode": "required",
			}
		],
		"footerlist": [
		]
	}
];


const subformFieldsThirdparty = [
	{
		"subformid": "nnpostmortemreportotherparty",
		"label": "Other Persons Involved",
		"table": "nnpostmortemreportotherparty",
		"sort": "nnpostmortemreportotherparty_name",
		"mobilerowtitlefieldidx":[0],
		"minrow": 0,
		"maxrow": 10,
		"allowdel": true,
		"fieldlist": [
			{
				"label": "Full Name",
				"field": "nnpostmortemreportotherparty_name",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "required",
			},
			{
				"label": "Role/Organization",
				"field": "nnpostmortemreportotherparty_role",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "required",
			},
			{
				"label": "Contact",
				"field": "nnpostmortemreportotherparty_contact",
				"subtotalid": "",
				"value": "",
				"input": "textbox",
				"mode": "normal",
			},
			{
				"label": "Details",
				"field": "nnpostmortemreportotherparty_notes",
				"subtotalid": "",
				"value": "",
				"input": "textarea",
				"mode": "required",
			},

		],
		"footerlist": [
		]
	}
];


const ENCODEMODE_FULL = 0;
const ENCODEMODE_CUSTOMER = 1;

const PostmortemformEditor = ({location, mode}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");
	const [userinfo, setUserinfo] = useState({});
	const [mainformdata, setMainformdata] = useState([]);

	const [nnpostmortemreportstatusid, setNnpostmortemreportstatusid] = useState(REPORTSTATUSID_DRAFT);

	const encodemode = typeof mode !== "undefined"?mode:ENCODEMODE_FULL;

	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		var pkid = 0;
		var tmpstatusid = nnpostmortemreportstatusid;
		var updatenotes = "";
		var encodemodedata = {};
		var tmpidx = 0;
		//var customparam = {};
		if (params.hasOwnProperty("documentid")) {
			pkid = params.documentid;
		}

		if (params.hasOwnProperty("nnpostmortemreportstatus_id")) {
			tmpstatusid = parseInt(params.nnpostmortemreportstatus_id, 10);
			if (isNaN(tmpstatusid)) {
				tmpstatusid = nnpostmortemreportstatusid;
			} else if (tmpstatusid < REPORTSTATUSID_DRAFT) {
				tmpstatusid = nnpostmortemreportstatusid;
			}
		}

		if (params.hasOwnProperty("nnpostmortemreport_scratch")) {
			if (params.nnpostmortemreport_scratch) {
				updatenotes = params.nnpostmortemreport_scratch;
			}
			delete params.nnpostmortemreport_scratch;
		}

		if (params.hasOwnProperty("onscustomer_id")) {
			tmpidx = 0;
			while (tmpidx < mainformdata.length) {
				if (mainformdata[tmpidx].field === "onscustomer_name") {
					params.nnpostmortemreport_title = mainformdata[tmpidx].value;
				}
				tmpidx++;
			}

		}
		//callback({"status":"Error", "message":"debug "+JSON.stringify(params)}); return;
		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			if (response.status === "OK") {
				postSaveUpdates(entity, token, response.documentid, pkid === 0, function() {
					if (tmpstatusid === REPORTSTATUSID_SUBMITTED) {
						// Finalized State
						logUpdates(response.documentid, token, tmpstatusid !== nnpostmortemreportstatusid?tmpstatusid:0, tmpstatusid !== nnpostmortemreportstatusid?"Submitted": updatenotes, function() {
							callback(response);
						});
						return;
					}
					callback(response);
				});

			} else {
				callback(response);
			}
		});
	}


	function postSaveUpdates(entity, token, pkid, isnew, callback)
	{
		if (isnew) {
			createDocActivities(entity, token, pkid, function() {
				callback();
			});
		} else {
			callback();
		}
	}

	function logUpdates(pkid, token, newstatusid, updatedetails, callback)
	{
		const curtimestamp = formatTools.getDateStr();
		if (updatedetails.length < 1) {
			callback();
			return;
		}
		webappsAPI.savePayload("nnpostmortemreportupdates", {
				"nnpostmortemreport_id": pkid,
				"nnpostmortemreportupdates_datetime": curtimestamp,
				"nnpostmortemreportupdates_encoder": userinfo.name,
				"nnpostmortemreportupdates_details": updatedetails
			}, token, [], [], function(response){
				if (newstatusid === REPORTSTATUSID_SUBMITTED || true) {
					var subformparamfields = [];
					var subformparam = {
						"nnpostmortemreport_id": pkid
					};
					var subformtable = "";
					if (encodemode === ENCODEMODE_CUSTOMER) {
						subformtable = "nnpostmortemreportcustomer";
						subformparamfields.push({"source":"onscustomer_id", "dest": "onsperson_id"});
						subformparamfields.push({"source":"nnpostmortemreport_rating", "dest": "nnpostmortemreportcustomer_rating"});
						subformparam.nnpostmortemreportcustomer_notes = "";
					}
					if (subformtable.length > 0 && subformparamfields.length > 0) {
						var mainformidx = 0;
						var tmpidx = 0;
						tmpidx = 0;
						while (tmpidx < subformparamfields.length) {
							mainformidx = 0;
							while (mainformidx < mainformdata.length) {
								if (mainformdata[mainformidx].field === subformparamfields[tmpidx].source) {
									subformparam[subformparamfields[tmpidx].dest] = mainformdata[mainformidx].value;
								}
								mainformidx++;
							}
							tmpidx++;
						}
						webappsAPI.savePayload(subformtable, subformparam, token, [], [], function() {
							callback();
						});
					} // has params
				} // submitted
				callback();
			});
	}

	function createDocActivities(entity, token, pkid, callback)
	{
		const docnum = "00000000"+pkid;
		webappsAPI.savePayload(entity, {
			documentid:pkid,
			nnpostmortemreport_code:docnum.substring(docnum.length-8),
			nnpostmortemreport_reportedby: userinfo.name
		}, token, [], [], function(response){
			callback();
		});
	}

	function customEditFieldInfo(inputlist, idx)
	{
		if (nnpostmortemreportstatusid !== REPORTSTATUSID_DRAFT) {
			if (inputlist[idx].field !== "nnpostmortemreport_scratch") {
				var tmpfield = JSON.parse(JSON.stringify(inputlist[idx]));
				tmpfield.mode = "readonly";
				return tmpfield;
			}
		}
		return inputlist[idx];
	}

	function customFieldFragment(inputlist, idx, isdisabled, fieldid, inputchangecallback)
	{
		if (inputlist[idx].field === "nnpostmortemreport_scratch") {
			if (nnpostmortemreportstatusid === REPORTSTATUSID_DRAFT) {
				// Hide field if draft
				return <></>
			}
		}
		// default
		return null;
	}

	function customSetFormData(newformdata)
	{
		var nnpostmortemreportstatus_id = REPORTSTATUSID_DRAFT;

		if (newformdata.hasOwnProperty("nnpostmortemreportstatus_id")) {
			if (newformdata.nnpostmortemreportstatus_id) {
				nnpostmortemreportstatus_id = newformdata.nnpostmortemreportstatus_id;
			}
		}

		setNnpostmortemreportstatusid(nnpostmortemreportstatus_id);
	}

	function checkduplicatesubformentry(subformdata, subformname, dbfield)
	{
		var refrowidx = 0;
		var rowidx = 0;
		var colidx = 0;

		if (rowidx < subformdata.length) {
			colidx = 0;
			while (colidx < subformdata[rowidx].length) {
				if (subformdata[rowidx][colidx].field === dbfield) {
					break;
				}
				colidx++;
			}
			if (colidx >= subformdata[rowidx].length) {
				// dbfield Not found
				return {"status":"OK"};
			}
		} else {
			return {"status":"OK"};
		}

		refrowidx = 0;
		while (refrowidx < subformdata.length-1) {
			if (parseInt(subformdata[refrowidx][0].value,10)===0) {
				// Not marked for deletion, skip
				rowidx = refrowidx+1;
				while(rowidx < subformdata.length) {
					if (parseInt(subformdata[rowidx][0].value,10)===0) {
						if (subformdata[rowidx][colidx].value === subformdata[refrowidx][colidx].value) {
							return {"status":"Error", "message":"Duplicated "+subformname+" "+subformdata[refrowidx][colidx].label+" at rows "+(refrowidx+1)+" and "+(rowidx+1)};
						}
					}
					rowidx++;
				}
			}
			refrowidx++;
		}
		return {"status":"OK"};
	}


	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		setMainformdata(mainform);
		var tmpres = {};
		var tmpupdatenotes = "";
		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "nnpostmortemreport_scratch") {
				tmpupdatenotes = mainform[idx].value;
			}
			idx++;
		}
		if (nnpostmortemreportstatusid === REPORTSTATUSID_SUBMITTED) {
			if (tmpupdatenotes.length < 1) {
				return {"status":"Error", "message":"Please provide update details"};
			}
		}

		idx = 0;
		while (idx < subformFields.length) {
			if (subformFields[idx].table === "nnpostmortemreportcustomer") {
				tmpres = checkduplicatesubformentry(subformlist[idx], subformFields[idx].label, "onsperson_id");
				if (tmpres.status !== "OK") {
					return tmpres;
				}
			} else if (subformFields[idx].table === "nnpostmortemreportotherparty") {
				tmpres = checkduplicatesubformentry(subformlist[idx], subformFields[idx].label, "nnpostmortemreportotherparty_name");
				if (tmpres.status !== "OK") {
					return tmpres;
				}
			}
			idx++;
		}
		return {"status":"OK"};
	}


	function customSubformViewmode(subform, subformidx)
	{
		return nnpostmortemreportstatusid === REPORTSTATUSID_SUBMITTED;
	}

	return <Layout location={location}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newUserHandler={(newtoken, newuserinfo)=>{setToken(newtoken); setUserinfo(newuserinfo)}}
			privatePage={true} usegatedcontent={true}
		>
			<WebappForm
				pagetitle={pagetitle}
				allowadd={true}
				allowdelete={false}
				entity={ENTITYID}
				mobilerowtitlefield={["nnpostmortemreport_code", "nnpostmortemreport_title"]}
				searchFields={searchFieldsHeader.concat(mode === ENCODEMODE_FULL?searchFieldDefault:searchFieldCustomer).concat(searchFieldsEnder)}
				editFields={[formFieldsHeader.concat(mode===ENCODEMODE_FULL?[]:formFieldsCustomer).concat(formFieldsEnder)]}
				editSubFormFields={subformFields.concat(encodemode === ENCODEMODE_FULL?subformFieldsCustomer:[]).concat(subformFieldsThirdparty)}

				customSubmit={customSubmit}
				customEditFieldInfo={customEditFieldInfo}
				customFieldFragment={customFieldFragment}
				customSetFormData={customSetFormData}
				customSubformViewmode={customSubformViewmode}
				customValidateValues={formValidateValues}

				token={token} />
		</Layout>

}


export default PostmortemformEditor;
